import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import FeatureSectionLight from '../components/featureSectionLight';
import FeatureCardsSection from '../components/featureCardsSection';

import NodeIcon from '../../assets/svg/nodejs-plain.svg';
import ReactIcon from '../../assets/svg/react-original.svg';
import FlutterIcon from '../../assets/svg/flutter-original.svg';
import GatsbyIcon from '../../assets/svg/gatsby-plain.svg';
import PythonIcon from '../../assets/svg/python-plain.svg';
import KafkaIcon from '../../assets/svg/kafka-plain.svg';
import PostgresIcon from '../../assets/svg/postgresql-plain.svg';
import MongoIcon from '../../assets/svg/mongodb-plain.svg';
import NestIcon from '../../assets/svg/nestjs-plain.svg';
import NextIcon from '../../assets/svg/nextjs-plain.svg';
import SwiftIcon from '../../assets/svg/swift-original.svg';
import RedisIcon from '../../assets/svg/redis-plain.svg';
import GolangIcon from '../../assets/svg/golang.svg';
import NowoczesneGraphics from '../../assets/svg/nowoczesne-technologie.svg';
import SubLayout from '../layouts/subLayout';
import SEO from '../components/seo';

const TechPage = () => {
  const data = useStaticQuery(graphql`
        query TechImages {
            nowoczesnetechnologie: file(
                relativePath: { eq: "nowoczesne-technologie.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 50 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

  return (
    <SubLayout
      title={(
        <>
          Our
          {' '}
          <span>Tech Stack</span>
        </>
              )}
      subtitle="PHP and jQuery? We don't to that here."
      img="/img/biuro-komputery-programowanie.jpg"
    >
      <SEO title="Tech Stack - iqcode Software House" description="A tech stack that we use at iqcode to create mobile apps, websites, server-side applications and progressive web apps." />
      <FeatureSectionLight
        title={(
          <>
            iqcode
            {' '}
            <span>Tech </span>
            Stack
          </>
                      )}
        img={(
          <div style={{ padding: '3%' }}>
            <NowoczesneGraphics style={{ width: '100%', height: 'auto' }} />
          </div>
                      )}
        desc={(
          <>
            <p>
              Choosing an appropriate technology stack is crucial
              for the software project's performance, stability and
              maintainability.
            </p>
            <p>
              At iqcode we us a wide variety of cutting-edge software development technologies,
              which allow us to deliver IT projects without unnecessary
              {' '}
              <strong>technical debt</strong>
              . As a result, our projects offer great performance, safety and scalability
              while remaining open to modification and extension so as to easily adjust
              to ever-changing business environment.
            </p>
            <p>
              Our areas of expertise include creation of modern progressive web apps (PWAs)
              in React.js, building fast and interactive websites with Gatsby.js and scalable
              server-side applications in Node.js, often leveraging microservices architecture
              for larger projects.
            </p>
          </>
                      )}
      />
      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Let's discuss your
            {' '}
            <span>Project</span>
            !
          </h3>
          <Link className="btn" to="/contact/">
            Contact Us
          </Link>
        </div>
      </div>
      <FeatureCardsSection
        id="backend"
        title={(
          <>
            <span>Backend</span>
            {' '}
            Technologies
          </>
                  )}
        subtitle="Geeky stuff powering our server-side applications"
        cards={[
          {
            title: 'Node.js',
            desc:
                            'Server-side JavaScript runtime environment which allows for creation of performant, I/O intensive backend applications.',
            icon: <NodeIcon />,
          },
          {
            title: 'NestJS',
            desc:
                            'Progressive Node.js backend application framework that integrates neatly with TypeScript.',
            icon: <NestIcon />,
          },
          {
            title: 'Python',
            desc:
                            'The world\'s most popular programming language that we use for building web apps, data science and machine learning projects.',
            icon: <PythonIcon />,
          },
          {
            title: 'Golang',
            desc:
                            'A modern programming language that we use for creating performance-focused backend applications as well as plugins for DevOps tools.',
            icon: <GolangIcon />,
          },
          {
            title: 'Redis',
            desc:
                            'In-memory database most commonly used as backend application read/write cache as well as a rate limiting store.',
            icon: <RedisIcon />,
          },
          {
            title: 'PostgreSQL',
            desc:
                            'Open-source relational database offering high availability and scalability thanks to its replication functionality.',
            icon: <PostgresIcon />,
          },
          {
            title: 'MongoDB',
            desc:
                            'Modern NoSQL database capable of storing enormous amounts of conveniently structured data leveraging the document-oriented data model.',
            icon: <MongoIcon />,
          },
          {
            title: 'Apache Kafka',
            desc:
                            'Distributed stream processing platform that we use as a means of asynchronous communication between microservices.',
            icon: <KafkaIcon />,
          },
        ]}
      />
      <FeatureCardsSection
        title={(
          <>
            <span>Frontend</span>
            {' '}
            Technologies
          </>
                  )}
        subtitle="For websites, Single Page Apps (SPAs) and Progressive Web Apps (PWAs)"
        cards={[
          {
            title: 'React.js',
            desc:
                            'A modern library aiding creation of browser interfaces leveraging the component model.',
            icon: <ReactIcon />,
          },
          {
            title: 'Gatsby.js',
            desc:
                            'React.js ecosystem for developing fast, interactive and secure static websites.',
            icon: <GatsbyIcon />,
          },
          {
            title: 'Next.js',
            desc:
                            'React.js framework for building web applications rendered both on the sever side and the client side.',
            icon: <NextIcon />,
          },
        ]}
      />
      <FeatureCardsSection
        title={(
          <>
            <span>Mobile</span>
            {' '}
            Technologies
          </>
                  )}
        subtitle="That we use to create Android and iOS apps"
        cards={[
          {
            title: 'React Native',
            desc:
                            'A well-known hybrid framework for creating mobile apps for Android i iOS from a single codebase.',
            icon: <ReactIcon />,
          },
          {
            title: 'Flutter',
            desc:
                            'A modern, performance-focused alternative for React Native.',
            icon: <FlutterIcon />,
          },
          {
            title: 'Swift',
            desc:
                            'iOS and iPadOS programming language for building truly native mobile applications.',
            icon: <SwiftIcon />,
          },
        ]}
      />
    </SubLayout>
  );
};

export default TechPage;
